import * as React from 'react';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
// @mui
import { DataGrid, GridToolbar, GridToolbarContainer, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { IconButton, Typography, GridCellParams, MuiEvent, Chip } from '@mui/material';
// components 
import { useSettingsContext } from '../../../components/settings/SettingsContext';
import { fDate } from '../../../utils/formatTime';

const DataGridCoPeople = (props) => {
  // console.log("DataGridCoPeople Rendered", props);

  const { themeStretch } = useSettingsContext();


  const columns = [
    {
      field: 'position',
      headerName: 'Title',
      width: 150,
      renderCell: (params) => (
        <span>{params.row.position}</span>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      renderCell: (params) => (
        <span>{params.row.name}</span>
      ),
    },
    {
      field: 'linkedin',
      headerName: 'Linkedin',
      width: 600,
      renderCell: (params) => (
          <a href={params.row.linkedin}
             target="_blank"
             rel="noreferrer"
             alt="logo"
          >{params.row.linkedin}</a>
        ),
    },
  ]

  // This is to hide the Datagrid header in people
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* Uncomment any component below if you want them back */}
        {/* <GridToolbarExport /> */}
        {/* <GridColumnsToolbarButton /> */}
        {/* <GridDensitySelector /> */}
      </GridToolbarContainer>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Typography align="center" color="textSecondary">
          No profiles found.
        </Typography>
      </div>
    );
  }

  return (
    <DataGrid
      columns={columns}
      rows={props.scanData}
      components={{ 
        Toolbar: CustomToolbar,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      // slots={{
      //   noRowsOverlay: CustomNoRowsOverlay,
      // }}
      density="compact"
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          hideToolbar: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );

}

export default DataGridCoPeople