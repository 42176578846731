import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Stack, CardHeader, Container, Tab, Card, Tabs, Box, Grid, Typography, IconButton, Chip, Divider, CircularProgress, useMediaQuery } from '@mui/material';
import ApexCharts from 'react-apexcharts';
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { DataGrid, GridToolbar, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import GenericDataGrid from '../mui/data-grid/GenericDataGrid';
// components
import { supabase } from '../../components/supabaseClient'
import { useSettingsContext } from '../../components/settings/SettingsContext';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/CustomBreadcrumbs';
import Iconify from '../../components/iconify';
import { PATH_DASHBOARD } from '../../routes/paths';
import { _userAbout, _userFeeds, _userFriends, _userGallery, _userFollowers } from '../../_mock/arrays';
import {
  Profile,
  ProfileCover,
  ProfileFriends,
  ProfileGallery,
  ProfileFollowers,
} from '../@dashboard/user/profile';
import EmployeesJobsHighlight from './EmployeesJobsHighlight'
import AppAreaInstalled from '../@dashboard/general/app/AppAreaInstalled'
import CompanyHeadcountChart from './CompanyHeadcountChart';
import CompanyRatingsChart from './CompanyRatingsChart';
import ProfileAbout from '../@dashboard/user/profile/home/ProfileAbout';
import ProfileSocialInfo from '../@dashboard/user/profile/home/ProfileSocialInfo';
import { fDateTime } from '../../utils/formatTime';
import { fCurrencyShort, fNumber, fPercent } from '../../utils/formatNumber';
import EmployeesJobsDelta from './EmployeesJobsDelta';
import DataGridCoHeadcount from '../mui/data-grid/DataGridCoHeadcount';
import DataGridCoRatings from '../mui/data-grid/DataGridCoRatings';
import DataGridCoFunds from '../mui/data-grid/DataGridCoFunds';
import DataGridCoPeople from '../mui/data-grid/DataGridCoPeople';


const Company = () => {
  const [companyData, setCompanyData] = useState([]);
  const { companyId } = useParams();
  const { themeStretch } = useSettingsContext();
  const [rows, setRows] = useState(companyData);
  const [currentTab, setCurrentTab] = useState('people');
  const [isLoading, setIsLoading] = useState(true);


  const latestFundingAmount = companyData.funding_rounds?.[0]?.funding_amount;
  const latestFundingRound = companyData.funding_rounds?.[0]?.funding_round;

  // hiding highlights based on browser width :
  const matchesSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const companyDomain = companyData.website
    ? companyData.website // check if we have website first
    : `https://${companyData.clearbit_domain}`; // fallback to clearbit domain

  const [ratingsHidden, setRatingsHidden] = useState(true);
  const [peopleHidden, setPeopleHidden] = useState(true);

  // Calculate headcount delta percentage
  const headcountRecords = companyData.headcount_normalized;
  const latestHeadcountRecord = headcountRecords?.slice(-1)[0];
  const latestHeadcount = latestHeadcountRecord?.headcount;
  const latestDate = new Date(latestHeadcountRecord?.date);

  // Calculate the target date 28 days ago
  const targetDate = new Date(latestDate);
  targetDate.setDate(targetDate.getDate() - 30);

  let headcountDeltaPercentage;
  if (headcountRecords?.length > 1) {
    // Find the closest historical record that is not newer than the target date
    let historicalRecord = headcountRecords
      .slice()
      .reverse() // Reverse to start checking from the oldest to ensure we get the closest past record
      .find(record => new Date(record.date) <= targetDate);

    // If no record is found older than the target date, use the oldest available record
    historicalRecord = historicalRecord || headcountRecords[0];

    // Ensure the historical record is not the same as the latest record
    if (historicalRecord && new Date(historicalRecord.date) < latestDate) {
      const earliestHeadcount = historicalRecord.headcount;
      if (earliestHeadcount && latestHeadcount) {
        headcountDeltaPercentage = (latestHeadcount - earliestHeadcount) / earliestHeadcount;
      }
    }
  }
  // console.log("headcountDeltaPercentage:", headcountDeltaPercentage, "Type:", typeof headcountDeltaPercentage);

  const TrendingInfo = ({ percent }) => {
    if (percent === 0) {
      return <Typography variant="subtitle2">-</Typography>;
    }
    const iconColor = percent < 0 ? 'error.main' : 'success.main';
    return (
      <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
        <Iconify
          icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'}
          sx={{
            mr: 1,
            p: 0.5,
            width: 24,
            height: 24,
            borderRadius: '50%',
            color: iconColor,
            bgcolor: percent < 0 ? 'rgba(255, 71, 87, 0.16)' : 'rgba(76, 175, 80, 0.16)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
        <Typography component="div" variant="subtitle2">
          {percent > 0 ? `+${fPercent(Math.abs(percent))}` : fPercent(Math.abs(percent))}
        </Typography>
      </Stack>
    );
  };

  const DataHighlightCard = ({ title, total, percent }) => (
    <Card sx={{ flexGrow: 1, p: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" gutterBottom>{title}</Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h3" sx={{ mr: 2 }}>
            {title.includes("Funding") ? fCurrencyShort(total) : fNumber(total)}
          </Typography>
          {percent !== undefined && percent !== null && (

            <TrendingInfo percent={percent * 100} /> // Multiply by 100 to convert from decimal to percentage
          )}
        </Box>
      </Box>
    </Card>
  );

  // GETTING RID OF TABS UNTIL MORE CONTENT
  // const TABS = [
  //     {
  //         value: 'people',
  //         label: 'People',
  //         component: (
  //             () => {
  //                 // console.log('CompanyData.people--', companyData.people); // Debug print statement

  //                 // Debug prints for mapping
  //                 if (companyData.people && companyData.people.length > 0) {
  //                     companyData.people.forEach(x => {
  //                         // console.log('Mapping person:', x);
  //                     });
  //                 }

  //                 return (
  //                     <Box sx={{ height: 500 }}>
  //                         <DataGridCoPeople
  //                             scanData={
  //                                 companyData.people && companyData.people.length > 0 ?
  //                                     companyData.people.map(x => ({
  //                                         "id": x.id,
  //                                         "linkedin": (x.linkedin_profile),
  //                                     })) : []
  //                             }
  //                         />
  //                     </Box>
  //                 );
  //             }
  //         )(),
  //     },
  // ];


  // Resizing (Mobile and Desktop) for Logo
  const [logoStyle, setLogoStyle] = useState({});

  useEffect(() => {
    const updateLogoStyle = () => {
      if (window.innerWidth < 1200) {  // adjust 768 to whatever breakpoint you want
        setLogoStyle({
          height: '100%',
          maxWidth: '200px',
          maxHeight: '40px',
        });
      } else {
        setLogoStyle({
          height: '100%',
          maxWidth: '220px',
          maxHeight: '60px',
        });
      }
    };

    updateLogoStyle();  // Set the initial style
    window.addEventListener('resize', updateLogoStyle);

    return () => {
      window.removeEventListener('resize', updateLogoStyle);  // Clean up the event listener on unmount
    };
  }, []);

  // Query DB for company data

  useEffect(() => {

    try {
      GetCompanyData(companyData);
    } catch (error) {
      console.error('loading error');
    }
  }, [companyId]);

  async function GetCompanyData() {
    const { data, error } = await supabase
      .from('companies')
      .select(`
          id,
          company,
          description,
          keywords,
          website_url,
          clearbit_logo,
          team_page,
          linkedin_profile,
          glassdoor_profile,
          twitter_profile,
          tiktok_profile,
          youtube_profile,
          instagram_profile,
          facebook_profile,
          discord_profile,
          location_city,
          location_country,
          funding_rounds: funding_rounds(
            funding_round_id,
            earliest_pub_date,
            company_name,
            funding_amount,
            funding_currency,
            funding_round,
            location_city,
            location_country,
            keywords,
            involved_vc_funds,
            involved_vc_funds_url,
            last_updated,
            funding_news_articles: funding_news_articles(
              funding_round_id,
              id,
              news_article_url,
              title,
              company_id
            )
          ),
          headcount_normalized: headcount_normalized(
            id,
            company_id,
            headcount,
            date
          ),
          people(
              id,
              company_id,
              secondary_company_id,
              linkedin_profile,
              position,
              name
            )
        `)
      .eq('id', companyId)
      .order('date', { foreignTable: 'headcount_normalized', ascending: true });

    if (JSON.stringify(data[0]) !== JSON.stringify(companyData)) {
      // console.log('companyData--', companyData[0]);
      setCompanyData(data[0]);
      setIsLoading(false);

    }
    if (data[0].glassdoor) {
      setRatingsHidden(false);
      // console.log ('glassdoor--', companyData.glassdoor);
    }
    // console.log('people3--', companyData.people);
    if (data[0].people && data[0].people.length > 0) {
      setPeopleHidden(false);
      // console.log('people4--', data[0].people.length);
    }
    // console.log ('ratingsHidden--', ratingsHidden);


    const peopleData = data[0];

    // console.log('newData--', data[0].people);
    // console.log('data--', data[0]);

  }

  return (
    <>
      <Helmet>
        <title> {`${companyData.company}`} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <CustomBreadcrumbs
                    //   heading="Company info"
                    links={[
                        { name: 'Company', href: PATH_DASHBOARD.root },
                        { name: companyData != null ? companyData.company : '' }
                    ]}
                /> */}

        <Container>
          {/* Company Logos Row */}
          <Stack direction="row"
            divider={<Divider
              orientation="vertical"
              flexItem />
            }
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{
              mb: 2,
              overflowX: 'auto', // <-- this makes it scrollable
              whiteSpace: 'nowrap' // <-- this ensures the items don't wrap to the next line
            }}
          >
            {
              companyData != null && companyData.clearbit_logo != null ? (
                companyData.clearbit_domain != null ? (
                  <a href={companyDomain} target="_blank" rel="noreferrer" alt="logo">
                    <img
                      src={companyData.clearbit_logo}
                      style={logoStyle}
                      alt="logo"
                    />
                  </a>
                ) : (
                  <img
                    src={companyData.clearbit_logo}
                    style={logoStyle}
                    alt="logo"
                  />
                )
              ) : ''
            }
            <h2 style={{ marginBottom: '3px' }}>{companyData != null ? companyData.company : ''}</h2>
            <Stack direction="row" spacing={0} alignItems="center">
              {
                companyData != null && companyData.website_url != null ?
                  (
                    <IconButton href=
                      {companyData.website_url}
                      target="_blank"
                      rel="noreferrer"
                      alt="company logo"
                    >
                      <Iconify icon='charm:link' color='default' />
                    </IconButton>) : ""
              }
              {
                companyData != null && companyData.clearbit_domain != null ?
                  (
                    <IconButton href=
                      {companyDomain}
                      target="_blank"
                      rel="noreferrer"
                      alt="company logo"
                    >
                      <Iconify icon='charm:link' color='default' />
                    </IconButton>) : ""
              }
              {
                companyData != null && companyData.linkedin_profile != null ?
                  (
                    <IconButton href=
                      {companyData.linkedin_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company linkedin"
                    >
                      <Iconify icon='eva:linkedin-fill' />
                    </IconButton>) : ""
              }
              {
                companyData != null && companyData.glassdoor_profile != null ?
                  (
                    <IconButton href=
                      {companyData.glassdoor_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company glassdoor"
                    >
                      <Iconify icon='acarbon:logo-glassdoor' color='default' />
                    </IconButton>) : ""
              }
              {
                companyData != null && companyData.twitter_profile != null ?
                  (
                    <IconButton href=
                      {companyData.twitter_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company twitter"
                    >
                      <Iconify icon='eva:twitter-fill' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.tiktok_profile != null ?
                  (
                    <IconButton href=
                      {companyData.tiktok_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company tiktok"
                    >
                      <Iconify icon='ic:baseline-tiktok' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.youtube_profile != null ?
                  (
                    <IconButton href=
                      {companyData.youtube_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company youtube"
                    >
                      <Iconify icon='carbon:logo-youtube' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.instagram_profile != null ?
                  (
                    <IconButton href=
                      {companyData.instagram_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company instagram"
                    >
                      <Iconify icon='ant-design:instagram-filled' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.facebook_profile != null ?
                  (
                    <IconButton href=
                      {companyData.facebook_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company facebook"
                    >
                      <Iconify icon='ant-design:facebook-filled' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.discord_profile != null ?
                  (
                    <IconButton href=
                      {companyData.discord_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company discord"
                    >
                      <Iconify icon='carbon:logo-discord' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.telegram_profile != null ?
                  (
                    <IconButton href=
                      {companyData.telegram_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company telegram"
                    >
                      <Iconify icon='ic:baseline-telegram' />
                    </IconButton>) : ""
              }

              {
                companyData != null && companyData.reddit_profile != null ?
                  (
                    <IconButton href=
                      {companyData.reddit_profile}
                      target="_blank"
                      rel="noreferrer"
                      alt="company reddit"
                    >
                      <Iconify icon='ic:baseline-reddit' />
                    </IconButton>) : ""
              }
            </Stack>
          </Stack>

          {/* Datagrid Title  */}
          <Stack direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="left"
            sx={{
              mb: 2,
              overflowX: 'auto', // <-- this makes it scrollable
              whiteSpace: 'nowrap' // <-- this ensures the items don't wrap to the next line
            }}>
            {/* <Typography variant="body2" style={{ marginRight: '8px' }}>Keywords:</Typography> */}
            {
              companyData.keywords && companyData.keywords.length > 0 ?
                companyData.keywords.map(keyword =>
                  <React.Fragment key={keyword}>
                    <a href={`/tracker/${keyword}`} style={{ textDecoration: 'none' }}>
                      <Chip
                        clickable
                        color="success"
                        variant="outlined"
                        size="small"
                        label={keyword}
                      />
                    </a>
                  </React.Fragment>
                ) : <span> - </span>
            }
            <Divider
              orientation="vertical"
              flexItem
            />
            {
              companyData.location_city ? (
                <a href={`/locations/city/${companyData.location_city}`} style={{ textDecoration: 'none' }}>
                  <Chip
                    clickable
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={companyData.location_city}
                  />
                </a>
              ) : (
                <span> - </span>
              )
            }

            {
              companyData.location_country ? (
                <a href={`/locations/country/${companyData.location_country}`} style={{ textDecoration: 'none' }}>
                  <Chip
                    clickable
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={companyData.location_country}
                  />
                </a>
              ) : (
                <span> - </span>
              )
            }
          </Stack>
          <Typography variant="body2" style={{ marginLeft: '6px', marginBottom: '10px' }}>
            {companyData.description}
          </Typography>


          <Grid container mb={2}>

            {/* --------- 
        Mobile View
        ------------ */}

            {matchesSM && (
              <Grid item xs={12} md={6} mb={2}>

                {latestHeadcount > 0 && (
                  <Box mb={2}>
                    <DataHighlightCard
                      title="Headcount"
                      total={latestHeadcount}
                      percent={headcountDeltaPercentage ? headcountDeltaPercentage.toFixed(5) : undefined}
                    />
                  </Box>
                )}

                <DataHighlightCard
                  title="Latest Funding"
                  total={latestFundingAmount}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              {!isLoading && companyData.headcount_normalized && companyData.headcount_normalized.length > 0 && (
                <CompanyHeadcountChart headcountData={companyData.headcount_normalized} />
              )}
            </Grid>

            {/* --------- 
            Desktop View
            ------------ */}

            {!matchesSM && (
              <Grid item xs={12} md={6} spacing={2}>

                {latestHeadcount > 0 && (
                  <Box mb={2} mt={2} ml={2}>
                    <DataHighlightCard
                      title="Headcount"
                      total={latestHeadcount}
                      percent={headcountDeltaPercentage ? headcountDeltaPercentage.toFixed(5) : undefined}
                    />
                  </Box>
                )}

                {latestHeadcount > 0 && (
                  <Box mb={2} ml={2}>
                    <DataHighlightCard
                      title="Latest Funding"
                      total={latestFundingAmount}
                    />
                  </Box>
                )}
              </Grid>
            )}
          </Grid>

          {/* --------- 
            Lower desktop card
            ------------ */}

          {/* {!matchesSM && ( */}
          {!matchesSM && headcountRecords && headcountRecords.length === 0 && (
            <Box mb={2}>
              <DataHighlightCard
                title="Latest Funding"
                total={latestFundingAmount}
              />
            </Box>
            )}

          {!matchesSM && companyData.people && companyData.people.length > 0 && (
            <Card
              sx={{
                mb: 2,
                mt: 2,
                height: 150,
                position: 'relative',
              }}
            >
              <Box sx={{ mx: 3, height: 500 }} dir="ltr">
                <Typography variant="h6" sx={{ mt: 2, ml: 1, mb: 2 }}>People</Typography>
                {isLoading && (
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Centers the loader correctly
                    zIndex: 2
                  }}>
                    <CircularProgress />
                  </div>
                )}
                {!isLoading && (
                  <DataGridCoPeople
                    scanData={
                      companyData.people && companyData.people.length > 0 ?
                        companyData.people.map(x => ({
                          "id": x.id,
                          "linkedin": x.linkedin_profile,
                          "position": x.position,
                          "name": x.name
                        })) : []
                    }
                  />
                )}
              </Box>
            
            {/* <Tabs
                            value={currentTab}
                            onChange={(event, newValue) => setCurrentTab(newValue)}
                            sx={{
                                marginLeft: 2, 
                            }}
                        >
                            {TABS.map((tab) => (
                                (tab.value !== "ratings" || !ratingsHidden) && (tab.value !== "people" || !peopleHidden) && (
                                    <Tab
                                        key={tab.value}
                                        value={tab.value}
                                        icon={tab.icon}
                                        label={tab.label}
                                    />
                                )
                            ))}
                        </Tabs> */}
          </Card>
          )}
          <Card sx={{ mb: 2, height: 500, position: 'relative' }}>
            <CardHeader title="Funding Round News" />
            <Box sx={{ mt: 3, mx: 3, height: 500 }} dir="ltr">
              {isLoading && (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Centers the loader correctly
                  zIndex: 2 // Ensure it's above any other content
                }}>
                  <CircularProgress />
                </div>
              )}
              {!isLoading && (
                <DataGridCoFunds
                  scanData={
                    companyData.funding_rounds && companyData.funding_rounds.length > 0 ?
                      companyData.funding_rounds.map(round => ({
                        id: round.funding_round_id,
                        funding_round_id: round.funding_round_id,
                        earliest_pub_date: round.earliest_pub_date,
                        funding_amount: round.funding_amount,
                        funding_round: round.funding_round,
                        involved_vc_funds: round.involved_vc_funds,
                        involved_vc_funds_url: round.involved_vc_funds,
                        last_updated: round.last_updated,
                        funding_news_articles: round.funding_news_articles
                      })) : []
                  }
                />
              )}
            </Box>
          </Card>
          {/* {TABS.map((tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>)} */}
        </Container>
      </Container>

    </>
  )
}

export default Company