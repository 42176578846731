import React, { useState, useEffect, useMemo, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
// @mui
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridFooterContainer,
  useGridApiContext,
  GridPagination,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { IconButton, Button, Typography, Chip, Skeleton, CircularProgress, InputAdornment, TextField, Container, Stack, Card, CardHeader, Box } from '@mui/material';
import { debounce } from 'lodash';
// components
import Iconify from '../../components/iconify/Iconify';
import { fCurrencyShort, fNumber, fPercent } from '../../utils/formatNumber';
import { fDate } from '../../utils/formatTime';
import Footer from '../../layouts/Footer';

// data
import { supabase } from '../../components/supabaseClient';

// ----------------------------------------------------------------------

// Custom Search Toolbar

const CustomToolbar = React.memo(({ onSearch }) => {
  const [inputValue, setInputValue] = useState('');

  const debouncedSearchHandler = useCallback(debounce((searchValue) => {
    onSearch(searchValue);
  }, 500), [onSearch]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    debouncedSearchHandler(value);
  };

  const handleClearSearch = () => {
    setInputValue('');
    debouncedSearchHandler('');
  };

  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </div>
      <div>
        <TextField
          variant="standard"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search"
          fullWidth
          sx={{
            minWidth: '200px', // Ensure a minimum width
          }}
          InputProps={{
            endAdornment: inputValue && (
              <IconButton onClick={handleClearSearch} aria-label="clear" size="small">
                <Iconify icon='pajamas:clear' color='default' />
              </IconButton>
            ),
          }}
        />
      </div>
    </GridToolbarContainer>
  );
}, (prevProps, nextProps) => prevProps.onSearch === nextProps.onSearch);

const Week = () => {
  const theme = useTheme();
  const [weekData, setWeekData] = useState([]);
  const { weekName } = useParams();

  // server side queries: 
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'latest_earliest_pub_date', sort: 'desc' }]); // Default sort
  const [filterModel, setFilterModel] = useState({});
  const [debouncedFilter, setDebouncedFilter] = useState(filterModel);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(weekData);

  const errorColor = theme.palette.error.main;
  const successColor = theme.palette.success.main;
  // console.log("tableData--", tableData)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  const columns = [
    {
      field: 'latest_earliest_pub_date',
      headerName: 'Date',
      width: 80,
      sortable: true,
      hide: false,
      filterable: false,
      renderCell: (params) =>
        <Typography variant="body2">
          {fDate(params.value)}
        </Typography>
    },
    {
      field: 'latest_funding_amount',
      headerName: 'Amount',
      width: 60,
      sortable: true,
      hide: false,
      filterable: false,
      renderCell: (params) => {
        const firstArticleURL = params.row.latest_funding_news_articles && params.row.latest_funding_news_articles[0]
          ? params.row.latest_funding_news_articles[0].news_article_url
          : null;
        const formattedAmount = fCurrencyShort(params.value);

        if (firstArticleURL) {
          return (
            <a href={firstArticleURL} target="_blank" rel="noopener noreferrer">
              {formattedAmount}
            </a>
          );
        }
        return (
          <Typography variant="body2">
            {formattedAmount}
          </Typography>
        );
      }
    },
    {
      field: 'latest_funding_round',
      headerName: 'Round',
      width: 90,
      sortable: true,
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='latest_funding_round'
            />;
          },
        },
        {
          label: 'does not contain',
          value: 'doesNotContain',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return !params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='latest_funding_round'
            />;
          },
        },
      ],
    },
    {
      field: 'logo',
      headerName: 'Logo',
      maxWidth: 100,
      sortable: false,
      hide: false,
      filterable: false,
      valueGetter: (params) => params.row.clearbit_logo,
      renderCell: (params) => {
        const logoUrl = params.value;
        return (
          logoUrl ? (
            <Link to={`/company/${params.row.id}`}>
              <img
                src={logoUrl}
                alt="company logo"
                style={{
                  maxHeight: '30px',
                  maxWidth: '50px',
                  display: 'block',
                }}
                title={params.row.company}
              />
            </Link>
          ) : (
            '-'
          )
        );
      },
      flex: 1,
      align: 'center',
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 110,
      maxWidth: 180,
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='company'
            />;
          },
        },
      ],
      valueGetter: (params) => params.row.company,
      renderCell: (params) => {
        const company = params.row.company;
        const companyId = params.row.id;
        return (
          company && companyId ? (
            <Link to={`/company/${companyId}`}>{company}</Link>
          ) : (
            '-'  // Display a dash if company or companyId is null
          )
        );
      },
    },
    {
      field: 'links',
      headerName: 'Links',
      width: 160,
      hide: false,
      filterable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          {params.row.website_url && (
            <IconButton
              href={params.row.website_url}
              target="_blank"
              rel="noopener noreferrer"
              alt="Company Website"
            >
              <Iconify icon='charm:link' color='default' />
            </IconButton>
          )}
          {params.row.linkedin_profile && (
            <IconButton
              href={params.row.linkedin_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="LinkedIn Profile"
            >
              <Iconify icon='eva:linkedin-fill' color='default' />
            </IconButton>
          )}
          {params.row.glassdoor_profile && (
            <IconButton
              href={params.row.glassdoor_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Glassdoor Profile"
            >
              <Iconify icon='acarbon:logo-glassdoor' color='default' />
            </IconButton>
          )}
          {params.row.twitter_profile && (
            <IconButton
              href={params.row.twitter_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Twitter Profile"
            >
              <Iconify icon='eva:twitter-fill' color='default' />
            </IconButton>
          )}
          {params.row.tiktok_profile && (
            <IconButton
              href={params.row.tiktok_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Tiktok Profile"
            >
              <Iconify icon='ic:baseline-tiktok' color='default' />
            </IconButton>
          )}
          {params.row.youtube_profile && (
            <IconButton
              href={params.row.youtube_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Youtube Profile"
            >
              <Iconify icon='carbon:logo-youtube' color='default' />
            </IconButton>
          )}
          {params.row.instagram_profile && (
            <IconButton
              href={params.row.instagram_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Instagram Profile"
            >
              <Iconify icon='ant-design:instagram-filled' color='default' />
            </IconButton>
          )}
          {params.row.facebook_profile && (
            <IconButton
              href={params.row.facebook_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Facebook Profile"
            >
              <Iconify icon='ant-design:facebook-filled' color='default' />
            </IconButton>
          )}
          {params.row.discord_profile && (
            <IconButton
              href={params.row.discord_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Discord Profile"
            >
              <Iconify icon='carbon:logo-discord' color='default' />
            </IconButton>
          )}
          {params.row.reddit_profile && (
            <IconButton
              href={params.row.reddit_profile}
              target="_blank"
              rel="noopener noreferrer"
              alt="Reddit Profile"
            >
              <Iconify icon='ic:baseline-reddit' color='default' />
            </IconButton>
          )}
        </div>
      ),
    },
    {
      field: 'keywords',
      headerName: 'Keywords',
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='keywords'
            />;
          },
        },
      ],
      width: 250,
      renderCell: (params) => {
        const keywords = params.row.keywords;

        return (
          <div style={{ display: 'flex', gap: '4px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {Array.isArray(keywords) && keywords.length > 0 ? (
              keywords.map((keyword) => (
                <a key={keyword} href={`/tracker/${keyword}`} style={{ textDecoration: 'none' }}>
                  <Chip
                    clickable
                    color="success"
                    variant="outlined"
                    size="small"
                    label={keyword}
                  />
                </a>
              ))
            ) : (
              <span> - </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 800,
      hide: false,
      filterable: false,
      renderCell: (params) => (
        <div style={{ maxHeight: '4em', overflowY: 'auto' }}>
          <span>{params.row.description || 'N/A'}</span>
        </div>
      ),
    },
    {
      field: 'investors',
      headerName: 'Investors',
      width: 250,
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='investors'
            />;
          },
        },
      ],
      renderCell: (params) => {
        const investors = params.row.investors;

        return (
          <div style={{ display: 'flex', gap: '4px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {Array.isArray(investors) && investors.length > 0 ? (
              investors.map((investor) => (
                <a key={investor} href={`/investor/${encodeURIComponent(investor)}`} style={{ textDecoration: 'none' }}>
                  <Chip
                    clickable
                    color="info"
                    variant="outlined"
                    size="small"
                    label={investor}
                  />
                </a>
              ))
            ) : (
              <span> - </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'location_city',
      headerName: 'City',
      width: 130,
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='location_city'
            />;
          },
        },
      ],
      renderCell: (params) => {
        const cityName = params.row.location_city;
        return (
          <div style={{ display: 'flex', gap: '4px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {cityName ? (
              <a href={`/locations/city/${cityName}`} style={{ textDecoration: 'none' }}>
                <Chip
                  clickable
                  color="secondary"
                  variant="outlined"
                  size="small"
                  label={cityName}
                />
              </a>
            ) : (
              <span> - </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'location_country',
      headerName: 'Country',
      width: 60,
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'contains',
          value: 'contains',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value.includes(filterItem.value);
            };
          },
          InputComponent: ({ item, applyValue, ...others }) => {
            return <FilterInputComponent
              value={item.value}
              onChange={(event) => applyValue({ ...item, value: event.target.value })}
              field='location_country'
            />;
          },
        },
      ],
      renderCell: (params) => {
        const countryName = params.row.location_country;
        return (
          <div style={{ display: 'flex', gap: '4px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {countryName ? (
              <a href={`/locations/country/${countryName}`} style={{ textDecoration: 'none' }}>
                <Chip
                  clickable
                  color="secondary"
                  variant="outlined"
                  size="small"
                  label={countryName}
                />
              </a>
            ) : (
              <span> - </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'latest_funding_news_articles',
      headerName: 'News Links',
      width: 450,
      hide: false,
      filterable: false,
      renderCell: (params) => {
        const articles = params.row.latest_funding_news_articles;
        if (articles && articles.length > 0) {
          const displayedArticles = articles.slice(0, 2);
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100px',
                overflowY: 'auto',
              }}
            >
              {displayedArticles.map((article, index) => (
                <span key={index}>
                  <a href={article.news_article_url} target="_blank" rel="noopener noreferrer">
                    {article.title}
                  </a>
                  <br />
                </span>
              ))}
            </div>
          );
        }
        return '';
      },
    },
    {
      field: 'jobs',
      headerName: 'Job Link',
      width: 80,
      filterable: false,
      renderCell: (params) => {
        const linkedinProfile = params.row ? params.row.linkedin_profile : null;
        let companyId = null;
        if (linkedinProfile) {
          const matches = linkedinProfile.match(/\/company\/(.*?)(\/|$)/);
          if (matches && matches[1]) {
            companyId = matches[1];
          }
        }
        return companyId ? (
          <IconButton
            href={`https://www.linkedin.com/company/${companyId}/jobs/`}
            target="_blank"
            rel="noopener noreferrer"
            alt="LinkedIn Jobs"
          >
            <Iconify icon='fluent:people-team-add-20-filled' color='default' />
          </IconButton>
        ) : null;
      }
    },
    {
      field: 'linkedin_profile',
      headerName: 'Founder LinkedIn',
      hide: false,
      filterable: true,
      width: 90,
      renderCell: (params) => {
        const linkedinProfile = params.row?.people?.[0]?.linkedin_profile || null;
        return (
          linkedinProfile ? (
            <div style={{ whiteSpace: 'nowrap' }}>
              <IconButton
                href={linkedinProfile}
                target="_blank"
                rel="noopener noreferrer"
                alt="Founder LinkedIn Profile"
              >
                <Iconify icon='eva:linkedin-fill' color='default' />
              </IconButton>
            </div>
          ) : ''
        );
      },
    },
    {
      field: 'latest_headcount',
      headerName: 'Employees',
      width: 70,
      filterable: true,
      filterOperators: [
        {
          label: 'Greater than',
          value: 'gt',
          getApplyFilterFn: (filterItem) => {
            return (params) => Number(params.value) > Number(filterItem.value);
          },
          InputComponent: ({ item, applyValue }) => {
            const [inputValue, setInputValue] = useState(item.value || '');
            const handleChange = (event) => {
              setInputValue(event.target.value);
              debouncedApplyValue(event.target.value);
            };

            const debouncedApplyValue = useMemo(() =>
              debounce((value) => {
                applyValue({ ...item, value });
              }, 1000),
              []
            );

            useEffect(() => {
              return () => {
                debouncedApplyValue.cancel();
              };
            }, [debouncedApplyValue]);

            return (
              <TextField
                type="number"
                value={inputValue}
                onChange={handleChange}
                placeholder="Headcount"
                variant="standard"
                fullWidth
              />
            );
          }
        },
        {
          label: 'headcount',
          value: 'lt',
          getApplyFilterFn: (filterItem) => {
            return (params) => Number(params.value) < Number(filterItem.value);
          },
          InputComponent: ({ item, applyValue }) => {
            const [inputValue, setInputValue] = useState(item.value || '');

            const handleChange = (event) => {
              setInputValue(event.target.value);
              debouncedApplyValue(event.target.value);
            };

            const debouncedApplyValue = useMemo(() =>
              debounce((value) => {
                applyValue({ ...item, value });
              }, 1000),
              []
            );

            useEffect(() => {
              return () => {
                debouncedApplyValue.cancel();
              };
            }, [debouncedApplyValue]);

            return (
              <TextField
                type="number"
                value={inputValue}
                onChange={handleChange}
                placeholder="Headcount"
                variant="standard"
                fullWidth
              />
            );
          }
        },
      ],
      renderCell: (params) => {
        const mostRecentHeadcount = params.row.latest_headcount || '';
        return <div>{mostRecentHeadcount ? fNumber(mostRecentHeadcount) : ''}</div>;
      },
    },
    {
      field: 'headcount_delta_percentage',
      headerName: 'Headcount Δ',
      width: 90,
      hide: false,
      filterable: true,
      filterOperators: [
        {
          label: 'Greater than',
          value: 'gt',
          getApplyFilterFn: (filterItem) => {
            return (params) => Number(params.value) > Number(filterItem.value);
          },
          InputComponent: ({ item, applyValue }) => {
            const [inputValue, setInputValue] = useState(item.value ? item.value * 100 : '');

            const handleChange = (event) => {
              const value = event.target.value.replace(/%$/, '');
              setInputValue(value);
              debouncedApplyValue(value);
            };

            const debouncedApplyValue = useMemo(() =>
              debounce((value) => {
                const decimalValue = value / 100;
                applyValue({ ...item, value: decimalValue.toString() });
              }, 1000),
              [applyValue, item]
            );

            useEffect(() => {
              return () => {
                debouncedApplyValue.cancel();
              };
            }, [debouncedApplyValue]);

            return (
              <TextField
                type="number"
                value={inputValue}
                onChange={handleChange}
                placeholder="0"
                variant="standard"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                sx={{ width: '35%' }}
              />
            );
          }
        },
        {
          label: 'Less than',
          value: 'lt',
          getApplyFilterFn: (filterItem) => {
            return (params) => Number(params.value) < Number(filterItem.value);
          },
          InputComponent: ({ item, applyValue }) => {
            const [inputValue, setInputValue] = useState(item.value ? item.value * 100 : '');

            const handleChange = (event) => {
              const value = event.target.value.replace(/%$/, '');
              setInputValue(value);
              debouncedApplyValue(value);
            };

            const debouncedApplyValue = useMemo(() =>
              debounce((value) => {
                const decimalValue = value / 100;
                applyValue({ ...item, value: decimalValue.toString() });
              }, 1000),
              [applyValue, item]
            );

            useEffect(() => {
              return () => {
                debouncedApplyValue.cancel();
              };
            }, [debouncedApplyValue]);

            return (
              <TextField
                type="number"
                value={inputValue}
                onChange={handleChange}
                placeholder="0"
                variant="standard"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                sx={{ width: '35%' }}
              />
            );
          }
        },
      ],
      renderCell: (params) => {
        const percent = params.value * 100;
        if (percent === 0) {
          return <div style={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary }}>-</div>;
        }
        const iconColor = percent < 0 ? theme.palette.error.main : theme.palette.success.main;
        const backgroundColor = theme.palette.mode === 'dark'
          ? (percent < 0 ? 'rgba(255, 71, 87, 0.16)' : 'rgba(76, 175, 80, 0.16)')
          : (percent < 0 ? 'rgba(244, 67, 54, 0.16)' : 'rgba(76, 175, 80, 0.16)');

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Iconify
              icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'}
              style={{
                marginRight: 8,
                padding: '0.25rem',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                color: iconColor,
                backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
            <span style={{ color: theme.palette.text.primary }}>{fPercent(Math.abs(percent))}</span>
          </div>
        );
      }
    },
  ];

  function FilterInputComponent({ value, onChange, field, ...other }) {
    const [inputValue, setInputValue] = useState(value || '');

    const handleChange = (event) => {
      setInputValue(event.target.value);
      debouncedOnChange(event.target.value);
    };

    const debouncedOnChange = useMemo(() => {
      const handler = debounce((value) => {
        onChange({
          target: {
            value
          }
        });
      }, 1000);
      return handler;
    }, [onChange]);

    useEffect(() => {
      return () => {
        debouncedOnChange.cancel(); // Cleanup the debouncer on component unmount
      };
    }, [debouncedOnChange]);

    return (
      <TextField
        variant="standard"
        value={inputValue}
        onChange={handleChange}
        {...other}
      />
    );
  }

  // Debounced Filter

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(filterModel);
    }, 1000); // 500 ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [filterModel]);

  useEffect(() => {
    getWeekData(page, pageSize, sortModel, debouncedFilter, searchText);
  }, [weekName, page, pageSize, sortModel, debouncedFilter, searchText]);

  async function getWeekData(page, pageSize, sortModel, filterModel, searchText) {
    setIsLoading(true);
    const sortField = sortModel.length > 0 && sortModel[0].field ? sortModel[0].field : null;
    const sortOrder = sortModel.length > 0 && sortModel[0].sort ? sortModel[0].sort : null;

    try {
      let query = supabase
        .from('companies_processed')
        .select(`
          id,
          company,
          description,
          keywords,
          website_url,
          clearbit_logo,
          team_page,
          linkedin_profile,
          glassdoor_profile,
          twitter_profile,
          tiktok_profile,
          youtube_profile,
          instagram_profile,
          facebook_profile,
          discord_profile,
          location_city,
          location_country,
          latest_headcount,
          headcount_delta_percentage,
          latest_funding_round_id,
          latest_earliest_pub_date,
          latest_funding_amount,
          latest_funding_currency,
          latest_funding_round,
          latest_funding_news_articles,
          investors,
          last_updated_at,
          people!people_company_id_fkey(
            id, 
            name, 
            role, 
            linkedin_profile, 
            email,
            github_profile, 
            twitter_profile, 
            source, 
            added_on, 
            last_updated, 
            linkedin_confidence, 
            position
          )
        `, { count: 'exact' })
        .order(sortField, { ascending: sortOrder === 'asc' })
        .order('id', { ascending: true })
        .range(page * pageSize, (page + 1) * pageSize - 1)
        .gt('latest_earliest_pub_date', startDate.toISOString())
        .lt('latest_earliest_pub_date', endDate.toISOString());

      if (sortField && sortOrder) {
        query = query.order(sortField, { ascending: sortOrder === 'asc' });
      }

      // Search toolbar
      if (searchText) {
        query = query.or(`company.ilike.%${searchText}%,description.ilike.%${searchText}%`);
      }

      // Dynamically applying filters
      if (filterModel.items) {
        filterModel.items.forEach(({ columnField, operatorValue, value }) => {
          switch (operatorValue) {
            case 'gt':
              query = query.gte(columnField, value);
              break;
            case 'lt':
              query = query.lte(columnField, value);
              break;
            case 'contains':
              if (columnField === 'keywords' || columnField === 'investors') {
                query = query.contains(columnField, [value]);
              } else {
                query = query.ilike(columnField, `%${value}%`);
              }
              break;
            case 'doesNotContain':
              if (columnField === 'keywords') {
                query = query.not(columnField, '=', [value]);
              } else {
                query = query.not(columnField, 'ilike', `%${value}%`);
              }
              break;
            default:
              break;
          }
        });
      }

      const { data, error, count } = await query;

      if (error) throw error;

      setWeekData(data);
      setRowCount(count);
    } catch (error) {
      console.error("Error fetching data:", error);
      setWeekData([]);
    } finally {
      setIsLoading(false);
    }
  };

  async function getAllWeekData(sortModel, filterModel, searchText) {
    try {
      const sortField = sortModel.length > 0 && sortModel[0].field ? sortModel[0].field : null;
      const sortOrder = sortModel.length > 0 && sortModel[0].sort ? sortModel[0].sort : null;
      
      let query = supabase
        .from('companies_processed')
        .select(`
          id,
          company,
          description,
          keywords,
          website_url,
          clearbit_logo,
          team_page,
          linkedin_profile,
          glassdoor_profile,
          twitter_profile,
          tiktok_profile,
          youtube_profile,
          instagram_profile,
          facebook_profile,
          discord_profile,
          location_city,
          location_country,
          latest_headcount,
          headcount_delta_percentage,
          latest_funding_round_id,
          latest_earliest_pub_date,
          latest_funding_amount,
          latest_funding_currency,
          latest_funding_round,
          latest_funding_news_articles,
          investors,
          last_updated_at,
          people!people_company_id_fkey(
            id, 
            name, 
            role, 
            linkedin_profile, 
            email,
            github_profile, 
            twitter_profile, 
            source, 
            added_on, 
            last_updated, 
            linkedin_confidence, 
            position
          )
        `, { count: 'exact' })
        .order(sortField, { ascending: sortOrder === 'asc' })
        .order('id', { ascending: true })
        .gt('latest_earliest_pub_date', startDate.toISOString())
        .lt('latest_earliest_pub_date', endDate.toISOString());
  
      // Apply search
      if (searchText) {
        query = query.or(`company.ilike.%${searchText}%,description.ilike.%${searchText}%`);
      }
  
      // Apply filters
      if (filterModel.items) {
        filterModel.items.forEach(({ columnField, operatorValue, value }) => {
          switch (operatorValue) {
            case 'gt':
              query = query.gte(columnField, value);
              break;
            case 'lt':
              query = query.lte(columnField, value);
              break;
            case 'contains':
              if (columnField === 'keywords' || columnField === 'investors') {
                query = query.contains(columnField, [value]);
              } else {
                query = query.ilike(columnField, `%${value}%`);
              }
              break;
            case 'doesNotContain':
              if (columnField === 'keywords') {
                query = query.not(columnField, '=', [value]);
              } else {
                query = query.not(columnField, 'ilike', `%${value}%`);
              }
              break;
            default:
              break;
          }
        });
      }
  
      const { data, error, count } = await query;
      if (error) throw error;
  
      return data;
    } catch (error) {
      console.error("Error fetching all data:", error);
      return [];
    }
  }

  const CustomPagination = () => {
    const apiref = useGridApiContext();

    const totalRowCount = apiref.current.state.pagination.rowCount;
    const formattedTotalRowCount = new Intl.NumberFormat('en-US').format(totalRowCount);

    return (
      <GridFooterContainer>
        <GridPagination />
        <Typography component="p" variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
          Total Rows: {formattedTotalRowCount}
        </Typography>
      </GridFooterContainer>
    );
  };

  function NumberInputComponent(inputProps) {
    return (
      <TextField
        {...inputProps}
        type="number"
        variant="standard"
      />
    );
  }

  // Sort Model

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      setSortModel(model);
    } else if (sortModel.length > 0 && model.length === 0) {
      const newSortDirection = sortModel[0].sort === 'desc' ? 'asc' : 'desc';
      setSortModel([{ ...sortModel[0], sort: newSortDirection }]);
    }
  };

  // Filter Model

  const handleFilterModelChange = (model) => {
    const newModel = {
      items: model.items.filter(item => {
        if (item.columnField === 'latest_funding_amount' && ['gt', 'lt'].includes(item.operatorValue)) {
          return true;  // Allow greater than and less than for Amount
        } if (item.columnField === 'latest_funding_round' && ['contains', 'doesNotContain'].includes(item.operatorValue)) {
          return true;
        } if (item.columnField === 'company' && item.operatorValue === 'contains') {
          return true;
        } if (item.columnField === 'keywords' && item.operatorValue === 'contains') {
          return true;
        } if (item.columnField === 'investors' && item.operatorValue === 'contains') {
          return true;
        } if (item.columnField === 'location_city' && item.operatorValue === 'contains') {
          return true;
        } if (item.columnField === 'location_country' && item.operatorValue === 'contains') {
          return true;
        } if (item.columnField === 'latest_headcount' && ['gt', 'lt'].includes(item.operatorValue)) {
          return true;
        } if (item.columnField === 'headcount_delta_percentage' && ['gt', 'lt'].includes(item.operatorValue)) {
          return true;
        }
        return false;  // Disallow all other filters
      })
    };
    setFilterModel(newModel);
  };

  const handleSearch = useCallback((value) => {
    setSearchText(value);
    setPage(0);
  }, [setSearchText, setPage]);

  const startDate = new Date(weekName);
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 6);

  function flattenObject(objectToFlatten) {
    if (!objectToFlatten) {
      return {};
    }
    const toReturn = {};
  
    Object.keys(objectToFlatten).forEach((key) => {
      if (key === 'company_people' && Array.isArray(objectToFlatten[key])) {
        toReturn[key] = objectToFlatten[key].map(person => person.linkedin_profile || '').filter(url => url !== '').join(', ');
      } else if (Array.isArray(objectToFlatten[key])) {
        toReturn[key] = JSON.stringify(objectToFlatten[key]);
      } else if (typeof objectToFlatten[key] === 'object' && objectToFlatten[key] !== null) {
        const flatObject = flattenObject(objectToFlatten[key]);
        Object.keys(flatObject).forEach((innerKey) => {
          toReturn[`${key}.${innerKey}`] = flatObject[innerKey];
        });
      } else {
        toReturn[key] = objectToFlatten[key];
      }
    });

    return toReturn;
  }

  const convertToCSV = (data) => {
    if (!data || !data.length || typeof data[0] !== 'object' || data[0] === null) {
      return ''; 
    }
    const headers = Object.keys(csvTemplate).join(',');

    const rows = data.map(row => {
      return Object.keys(csvTemplate).map(key => { 
        const value = row[key];
        
        if (value === null || value === undefined) {
          return '""'; 
        }
        
        const strValue = `${value}`.replace(/"/g, '""');
  
        return `"${strValue}"`;
      }).join(',');
    }).join('\n');
  
    return `${headers}\n${rows}`;
  };

  const downloadCSV = (data) => {
    const csvContent = convertToCSV(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    
    const startDateString = startDate.toISOString().split('T')[0]; 
    const endDateString = endDate.toISOString().split('T')[0];
    
    link.setAttribute('download', `rivadata-weekly-${startDateString}-to-${endDateString}.csv`); 
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function normalizeData(data, template) {
    return data.map(item => {
      const newItem = {};
      Object.keys(template).forEach(key => {
        const originalKey = key.replace('company_', '');
        newItem[key] = item[originalKey] || item[key] || template[key];
      });
      newItem.date = item.latest_earliest_pub_date ? new Date(item.latest_earliest_pub_date).toLocaleDateString('en-CA') : '';
      newItem.amount = item.latest_funding_amount ? `$${item.latest_funding_amount.toLocaleString()}` : '';
      newItem.round = item.latest_funding_round || '';  
      return newItem;
    });
  }

  const handleDownloadCSV = async () => {
    setIsLoading(true);
    try {
      const fullData = await getAllWeekData(sortModel, filterModel, searchText);
      const normalizedData = normalizeData(fullData, csvTemplate);
      const flattenedData = normalizedData.map(item => flattenObject(item));
      downloadCSV(flattenedData);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const csvTemplate = {
    date: null,
    amount: null,
    round: null,
    company_id: null,
    company: null,
    company_keywords: null,
    company_description: null,
    company_website_url: null,
    company_investors: null,
    company_location_city: null,
    company_location_country: null,
    company_funding_rounds: null,
    company_facebook_profile: null,
    company_linkedin_profile: null,
    company_twitter_profile: null,
    company_tiktok_profile: null,
    company_youtube_profile: null,
    company_instagram_profile: null,
    company_discord_profile: null,
    company_people: null,
  };

  const normalizedData = normalizeData(weekData, csvTemplate);
  const flattenedData = normalizedData.map(item => flattenObject(item));
  const csvOutput = convertToCSV(flattenedData);

  return (
    <Container maxWidth={false}>
      <Stack>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Chip
                    href={`/week/${weekName}`}
                    sx={{ verticalAlign: 'middle', md: 2, mb: 2 }}
                    color="default"
                    variant="outlined"
                    size="small"
                    label={`${startDate.toISOString().split('T')[0]} - ${endDate.toISOString().split('T')[0]}`}
                  />
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleDownloadCSV}
                    aria-label="download"
                    sx={{
                      borderRadius: '20px',
                      verticalAlign: 'middle',
                      md: 2,
                      mb: 2,
                    }}
                  >
                    Export to CSV
                  </Button>
                </Box>
              </>
            }
          />
          <Box sx={{ height: 690 }}>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                loading={isLoading}
                columns={columns}
                rows={weekData}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                onPageChange={newPage => setPage(newPage)}
                rowCount={rowCount}
                paginationMode="server"
                pagination
                sortingMode="server"
                filterMode="server"
                sortModel={sortModel}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={handleFilterModelChange}
                style={{ height: 700 }}
                autoHeight
                hideScrollbar
                density="compact"
                disableDensitySelector
                localeText={{
                  MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) =>
                      `${from.toLocaleString()} - ${to.toLocaleString()} of ${count.toLocaleString()}`,
                  },
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{
                  toolbar: { onSearch: handleSearch },
                }}
              />
            </div>
          </Box>
        </Card>
      </Stack>
      <br /><br />
      <Footer />
    </Container>
  )
}

export default Week;
