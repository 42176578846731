import PropTypes from 'prop-types';
import { useState, useEffect } from 'react'
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Button, Typography } from '@mui/material';
// utils
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import { supabase } from '../../../components/supabaseClient'
import Account from '../../../sections/auth/Account'
import SupabaseAuth from '../../../sections/auth/SupabaseAuth'
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import NavSectionHorizontal from '../../../components/nav-section/horizontal/NavSectionHorizontal'
import navConfig from '../nav/config';
import { StyledCard, StyledWrap, MaskControl } from '../../../components/settings/styles';
import SvgColor from '../../../components/svg-color/SvgColor';


// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {

  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  const currentLocation = useLocation();


  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const { themeMode, onToggleMode } = useSettingsContext();

  const navigate = useNavigate();
  const routeChange = () => {
    const path = `/login`;
    navigate(path);
  }

  const logoutfunc = async () => {
    supabase.auth.signOut();
    navigate('/home');
  };

  const renderContent = (
    <>
      {currentLocation.pathname !== '/home' && (
        <IconButton onClick={() => window.history.back()}>
          <Iconify icon="material-symbols:arrow-back-rounded" sx={{ color: 'text.secondary', width: 30, height: 30}} />
        </IconButton>
      )}
      {isNavHorizontal && <Logo sx={{ mr: 0, ml: 0 }} />}

      {/* <Typography variant="h4" color="textPrimary"></Typography> */}
      {/* <Searchbar /> */}

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1.5 }}>

        {/* <LanguagePopover /> */}
        {/* <NotificationsPopover /> */}

        {isDesktop && isNavHorizontal && <NavSectionHorizontal data={navConfig} />}

        {/* ------ darkmode button ---------------- */}
          <IconButton color={themeMode === 'dark' ? 'warning' : 'default'} onClick={onToggleMode} >
            <SvgColor
              src={`/assets/icons/setting/ic_${themeMode === 'light' ? 'moon' : 'sun'}.svg`}
            />
          </IconButton>

          {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" sx={{ color: 'text.secondary' }}/>
          </IconButton>
        )}

        {/* ------ Sign In button ---------------- */}
        {!session ?
          <Button 
              LoadingButton 
              component={RouterLink} 
              to="/signup"
              color="primary"
              variant="outlined"
              size="large"
              sx={{ 
                mb: 2, 
                position: 'relative',
                paddingRight: 3,
                borderRadius: '50px',
                '&:hover': { 
                    color: 'primary.main', // Border color on hover
                },
            }}
          >
            Sign in
          </Button>
           : null
        }

        {/* <ContactsPopover /> */}
        {session && (
        <AccountPopover />
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
