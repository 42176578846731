import { useContext } from 'react';
//
import { AuthContext } from './SupabaseContext';
// import { AuthContext } from './Auth0Context';
// import { AuthContext } from './FirebaseContext';
// import { AuthContext } from './AwsCognitoContext';

// ----------------------------------------------------------------------

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  // console.log('--debug UseAuthContext:', context);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};
