import { useState, useEffect } from 'react';
import { supabase } from '../components/supabaseClient';

const useSession = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      
      // If there's no error and the session exists, set it in state
      if (!error && session) {
        setSession(session);
      } else {
        // Handle no session or error
        setSession(null);
      }
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
    });

    // Cleanup listener on unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return session;
};

export default useSession;