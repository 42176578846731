import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Button, Typography, Stack, Link } from '@mui/material';
import { supabase } from '../../components/supabaseClient';

import useSession from '../../hooks/useSession'; 

const Account = () => {
  const session = useSession(); 
  const [loading, setLoading] = useState(false)
  // const [username, setUsername] = useState(null)
  // const [website, setWebsite] = useState(null)
  // const [avatarURL, setAvatarUrl] = useState(null)

  useEffect(() => {
    // console.log('session--', session)
    if (session && session.user) {
      // getProfile();
    }
  }, [session]);

  // const getProfile = async () => {
  //   try {
  //     setLoading(true)
  //     const { user } = session

  //     const { data, error, status } = await supabase
  //       .from('profiles')
  //       .select(`username, website, avatar_url`)
  //       .eq('id', user.id)
  //       .single()

  //     if (error && status !== 406) {
  //       throw error
  //     }

  //     if (data) {
  //       setUsername(data.username)
  //       setWebsite(data.website)
  //       setAvatarUrl(data.avatar_url)
  //     }
  //   } catch (error) {
  //     alert(error.message)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // const updateProfile = async (e) => {
  //   e.preventDefault()

  //   try {
  //     setLoading(true)
  //     const { user } = session

  //     const updates = {
  //       id: user.id,
  //       username,
  //       website,
  //       avatarURL,
  //       updated_at: new Date(),
  //     }

  //     const { error } = await supabase.from('profiles').upsert(updates)

  //     if (error) {
  //       throw error
  //     }
  //   } catch (error) {
  //     alert(error.message)
  //   } finally {
  //     setLoading(false)
  //   }
  // } 

  const signOut = async () => {
    setLoading(true);
    await supabase.auth.signOut();
    setLoading(false);
  };

  const deleteUser = async () => {
    const confirm = window.confirm('Are you sure you want to delete your profile? This will remove all your data.');

    if (confirm) {
      try {
        setLoading(true);
        const { user } = session;

        // Delete the user profile from the 'profiles' table
        const { error } = await supabase
          .from('users')
          .delete()
          .eq('id', user.id);

        if (error) {
          console.error('Error deleting user:', error);
        }

        // Optionally, sign the user out after deleting their profile
        await supabase.auth.signOut();
        setLoading(false);
        
        // Redirect or inform the user that their profile has been deleted, as needed
        alert('Your profile has been deleted.');
        // window.location.href = '/'; // Redirect to home or sign-in page, if desired
      } catch (error) {
        alert(error.message);
      }
    }
  };

  if (!session || !session.user) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          mb: 1,
          position: 'relative',
          maxWidth: 400,
          margin: 'auto',
          p: 4,
          mt: 8,
        }}
      >
        <Typography variant="h6" gutterBottom textAlign="center">
          You must be logged in to view this page.
        </Typography>
        <Link component={RouterLink} to="/signin" color="primary">
        <Button 
                  variant="outlined"
                  sx={{ 
                    verticalAlign: 'middle', 
                    md: 2, 
                    mb: 2 ,
                    borderRadius: '50px',
                  }}
                >
                  Go to sign in
          </Button>
        </Link>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        mb: 1,
        position: 'relative',
        maxWidth: 400,
        margin: 'auto',
        p: 4,
        mt: 8,
      }}
    >
      <div aria-live="polite">
        {loading ? (
          <Typography variant="h6">Saving...</Typography>
        ) : (
          <>
            <Typography variant="h6">
              You are currently signed in with the email:
            </Typography>
            <Typography variant="subtitle1">{session.user.email}</Typography>
          </>
        )}
      </div>
      <Stack direction="row" spacing={2} sx={{ mt: 2, justifyContent: 'space-between' }}>
        <Button 
          color="error"
          onClick={deleteUser}
          sx={{ borderRadius: '50px' }}
        >
          Delete profile
        </Button>
        <Button 
          variant="outlined"
          onClick={signOut}
          sx={{ borderRadius: '50px' }}
        >
          Sign out
        </Button>
      </Stack>
    </Stack>
  );
};

export default Account;