import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fCurrencyShort(number) {
  const format = number ? numeral(number).format('$0.00a') : '';

  return result(format, '.00');
}


export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0,0.0%') : '0%';

  return result(format, '.000');
}

export function fPercent2(number) {
  // Remove division by 100 to correctly format the decimal as a percentage.
  const format = number ? numeral(number).format('0.00%') : '0.00%';
  return format;
}


export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fOneDecimalNumber(number) {
  const format = number ? numeral(number).format('0,0.0') : '';

  return format;
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
