import * as React from 'react';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
// @mui
import { DataGrid, GridToolbar, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { IconButton, Typography, GridCellParams, MuiEvent, Chip } from '@mui/material';
// components 
import { useSettingsContext } from '../../../components/settings/SettingsContext';
import { fDate } from '../../../utils/formatTime';

const DataGridCoHeadcount = (props) => {

  const { themeStretch } = useSettingsContext();

  // console.log('employees--', props.employees);
  // console.log('timestamp--', props.timestamp);

  const columns = [

    {
      field: 'timestamp',
      headerName: 'Scan',
      flex: 0.75,
      valueGetter: (params) => { return params.row.timestamp },
      renderCell: (params) => {
        // Log the value of the params.value parameter
        // console.log('params--', params.value);
        return (
          <Typography variant="body2">
            {fDate(params.value * 1000)}
          </Typography>
        );
      },
    },
    {
      field: 'employees',
      headerName: 'Employees',
      flex: 0.5
    },
    {
      field: 'jobOpenings',
      headerName: 'Job openings',
      flex: 0.5
    },
  ]

  return (
    <DataGrid
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: 'timestamp', sort: 'desc' }],
        },
      }}
      rows={props.scanData}
      density="compact"
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );

}

export default DataGridCoHeadcount 