import * as React from 'react';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
// @mui
import { DataGrid, GridToolbar, GridToolbarContainer, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { IconButton, Typography, Box, GridCellParams, MuiEvent, Chip } from '@mui/material';
// components 
import { useSettingsContext } from '../../../components/settings/SettingsContext';
import { fDate } from '../../../utils/formatTime';
import { fCurrencyShort } from '../../../utils/formatNumber';
import Iconify from '../../../components/iconify/Iconify';

const DataGridCoFunds = (props) => {
  // console.log("DataGridCoPeople Rendered", props);

  const { themeStretch } = useSettingsContext();

  const columns = [
    {
      field: 'earliest_pub_date',
      headerName: 'Date',
      width: 100,
      renderCell: (params) => (
        <Typography variant="body2">
          {fDate(params.value)}
        </Typography>
      ),
    },
    {
      field: 'funding_amount',
      headerName: 'Amount',
      width: 75,
      renderCell: (params) => (
        <Typography variant="body2">
          {fCurrencyShort(params.value)}
        </Typography>
      ),
    },
    {
      field: 'funding_round',
      headerName: 'Round',
      width: 75,
    },
    {
      field: 'funding_news_articles',
      headerName: 'News Articles',
      width: 350,
      renderCell: (params) => {
        // params.value should contain the array of articles
        const articles = params.value;
        return (
          <Box>
            { articles && articles.length > 0 ? articles.map((article) => (
              <Box key={article.id}>
                <a href={article.news_article_url} target="_blank" rel="noopener noreferrer">
                  {article.title}
                </a>
              </Box>
            )) : 'No articles available' }
          </Box>
        );
      }
    },
    {
      field: 'involved_vc_funds',
      headerName: 'Investors',
      width: 300,
      renderCell: (params) => {
        const funds = params.value;
    
        return (
          <div style={{ display: 'flex', gap: '4px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {Array.isArray(funds) && funds.length > 0 ? (
              funds.map((fund) => (
                <a key={fund} href={`/investor/${encodeURIComponent(fund)}`} style={{ textDecoration: 'none' }}>
                  <Chip
                    clickable
                    color="info" // Adjust the color as needed
                    variant="outlined"
                    size="small"
                    label={fund}
                  />
                </a>
              ))
            ) : (
              <Typography variant="body2"> - </Typography>
            )}
          </div>
        );
      }
    },
  ]

  // This is to hide the Datagrid header in people
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* Uncomment any component below if you want them back */}
        {/* <GridToolbarExport /> */}
        {/* <GridColumnsToolbarButton /> */}
        {/* <GridDensitySelector /> */}
      </GridToolbarContainer>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Typography align="center" color="textSecondary">
          No profiles found.
        </Typography>
      </div>
    );
  }

  return (
    <DataGrid
      columns={columns}
      rows={props.scanData}
      components={{ 
        Toolbar: CustomToolbar,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      pageSize={15}
      disableDensitySelector
      hideScrollbar
      getRowHeight={() => 'auto'}
      density="compact"
      // slots={{
      //   noRowsOverlay: CustomNoRowsOverlay,
      // }}
      density="compact"
      sortModel={[
        {
          field: 'earliest_pub_date',
          sort: 'desc',
        }
      ]}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          hideToolbar: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
          
}

export default DataGridCoFunds