// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  tracker: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Riva Data',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.one },
      // {
      //   title: 'Use Cases',
      //   path: PATH_DASHBOARD.user.four,
      //   // icon: ICONS.user,
      //   children: [
      //     { title: 'explore fundraising', path: PATH_DASHBOARD.user.four },
      //     { title: 'explore headcount', path: PATH_DASHBOARD.user.five },
      //     { title: 'explore crypto', path: PATH_DASHBOARD.user.trackerCrypto },
      //     // { title: 'example company', path: PATH_DASHBOARD.user.seven },
      //   ],
      // },
      {
        title: 'keywords',
        path: '/tracker/AI',
        children: [
          { title: 'AI', path: '/tracker/AI' },
          { title: 'Healthcare', path: '/tracker/healthcare' },
          { title: 'Fintech', path: '/tracker/fintech' },
          { title: 'Cybersecurity', path: '/tracker/cybersecurity' },
        ],
      },
      {
        title: 'investors',
        path: '/investor/Y%20Combinator',
        children: [
          { title: 'Y Combinator', path: '/investor/Y%20Combinator' },
          { title: 'Andreessen Horowitz', path: '/investor/Andreessen%20Horowitz' },
          { title: 'Insight Partners', path: '/investor/Insight%20Partners' },
          { title: 'Sequoia Capital', path: '/investor/Sequoia%20Capital' },
          { title: 'Google Ventures', path: '/investor/GV' },
        ],
      },
      {
        title: 'locations',
        path: '/locations/city/london',
        children: [
          { title: 'New York', path: '/locations/city/New%20York' },
          { title: 'London', path: '/locations/city/london' },
          { title: 'San Francisco', path: '/locations/city/San%20Francisco' },
          { title: 'United States', path: '/locations/country/US' },
          { title: 'India', path: '/locations/country/IN' },
          { title: 'Dubai', path: '/locations/city/Dubai' },
        ],
      },
      { title: 'About', path: PATH_DASHBOARD.two },
      { title: 'Pricing', path: '/pricing' },
    ],
  },
];

export default navConfig;
