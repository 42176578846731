import { useState, useEffect } from 'react';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import Chart, { useChart } from '../../components/chart'; // Adjust the import path based on your project structure

export default function CompanyHeadCountChart({ headcountData }) {
  // Process headcountData to extract dates and headcounts
  const processData = (data) => {
    const categories = data.map(item => item.date);
    const headcounts = data.map(item => item.headcount);
    
    return { categories, headcounts };
  };

  const { categories, headcounts } = processData(headcountData);

  const chartData = {
    colors: ['#00AB55', '#36B37E'],
    categories,
    series: [
      {
        name: 'Employees',
        data: headcounts,
      },
    ],
    options: {
      yaxis: {
        labels: {
          formatter(val) {
            return val.toLocaleString();
          }
        }
      },
      tooltip: {
        y: {
          formatter(val) {
            return val.toLocaleString();
          }
        }
      },
    },
  };

  const chartOptions = useChart({
    colors: chartData.colors,
    xaxis: {
      categories: chartData.categories,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    ...chartData.options,
  });

  return (
    <Card>
      <CardHeader title="Employees" />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart
          type="bar"
          series={chartData.series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
